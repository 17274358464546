<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">
                <FormClient :client_id="note.client_id" v-if="Object.keys(note).length > 0" />
                <div>
                    <textarea v-model="note.note" required />
                </div>
                <button type="submit">Save</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import FormClient from '@/components/formpieces/FormClient';

    import { notes } from '@/util/apiRequests';
    import { ValidationObserver } from 'vee-validate';

    export default {
        data() {
            return {
                note: {}
            };
        },
        components: { FormClient, ValidationObserver },
        methods: {
            async getChartNote() {
                if (this.$route.params.id) {
                    let { data } = await this.$api.get(notes.getNote(this.$route.params.id));
                    this.note = data?.info[0] ?? {};
                } else {
                    this.$toasted.error('Could not retrieve chart note');
                }
            },
            async submit() {
                let data = this.note;
                // let activity = {};
                data.note_type = 'chart';
                const form = this.$refs.form;
                const isValid = await form.validate();
                if (!isValid) {
                    // this.$toasted.error('Invalid fields');
                    return;
                }
                try {
                    // eslint-disable-next-line no-unused-vars
                    let page = this.$store.getters['availablePages'].find(
                        (element) => element.component_name == 'ChartNote'
                    );
                    data.page_id = page.id;
                    await this.$api.put(notes.saveNote(this.$route.params.id), data);
                    this.$toasted.success('Successfully added your chart note.');
                    //if(res.status)
                } catch (error) {
                    this.$toasted.error('There was an error posting your note.');
                    //this.$toasted.error('Could not submit your data.');
                }
            }
        },
        async created() {
            await this.getChartNote();
        }
    };
    /**
     * Potential Ways to get here,
     * Record ID, so we use record ID to access this page.
     */
</script>

<style></style>
